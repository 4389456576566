import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background: #000;
  align-items: center;

  p {
    color: #fff;
  }
`;